import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NewsLetterSection } from "../components";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactComponent as Img } from "../assets/img/main_white.svg";
// import { ReactComponent as Imgmobile } from "../assets/img/main_white_mobile.png";
import { baseUrl } from "../mainUrl";
import { Helmet } from "react-helmet-async";

//  import { ReactComponent as Crypto} from "../assets/images/home_crypto.png"
// import { ReactComponent as Fractional } from "../assets/img/home_fractional.svg";
// import { ReactComponent as Options } from "../assets/img/home_options.svg";
// import { ReactComponent as Stock } from "../assets/img/home_stock.svg";

const Home = () => {
  AOS.init({
    duration: 1200,
  });
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 5,
      },
      1000: {
        items: 7,
      },
    },
  };
  return (
    <React.Fragment>
      {/* <!-- banner_section --> */}
      <Helmet>
        <title>veloxglobal.com</title>
          <meta name="description" content="Velox|velox clearing|Velox global|correspondent clearing broker in US|Top US Clearing brokers|Top clearing and custody firms|Top US clearing broker firms|Zinvest|alpaca" />
        <link rel="canonical" href="/" />
      </Helmet>
      <section className="banner_section" style={{paddingTop:"10px"}}>
        <div className="banner-inner ">
          <div className="layer-images">
            <a href={`${baseUrl}solutions`} className="stock_img">
              {" "}
              <img src="/images/home_stock.png" alt="homestock" />
            </a>
            <a href={`${baseUrl}solutions`} className="option_img">
              {" "}
              <img src="/images/home_options.png" alt="homeoption" />
            </a>
            <a href={`${baseUrl}solutions`} className="fractional_img">
              {" "}
              <img src="/images/home_fractional.png" alt="fractional" />
            </a>
            {/* <a href={`${baseUrl}solutions`}  className="crypto_img">
              {" "}
              <img src="/images/home_crypto.png" alt="crypto" />
            </a> */} 
            <div className="layer1" >
              <Img className="graph_img" alt="graph img"/>
              <img src="/images/main_white_mobile_nCrypto.png" className="graph_img_mobile" alt="graph mobile img" />
            </div>
          </div>
          <div className="container">
            <div className="banner_info">
              <div><h1>Next Gen</h1>
              <div className="content wrapper_scrolling">
                <h1>API for</h1>
                <div className="words">
                  <span>
                    <h1>Trading</h1>
                  </span>
                  <span>
                    <h1>Clearing</h1>
                  </span>
                  <span>
                    <h1>Custody</h1>
                  </span>
                  <span>
                    <h1>Execution</h1>
                  </span>
                  <span>
                    <h1>Locates</h1>
                  </span>
                  <span>
                    <h1>Transfers</h1>
                  </span>
                  <span>
                    <h1>Trading</h1>
                  </span>
                </div>
              </div></div>
              <div className="button">
                <button className="btn_pink ">
                <a href={`${baseUrl}contact`} >Get Access</a>
                </button>
                <button className="btn-blue outline ">
                <a href="https://www.velox-global.com/apidoc/" target="_blank" rel="noreferrer">Start Building</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- explore section --> */}
      <section className="explore_section container">
        <div className="explore_inner">
          <div className="explore_content">
            <h4 className="text-purple">Stats</h4>
            <p>as of September 2022</p>
          </div>
          <div className="explore_content">
            <h4 className="text-green">$1B+</h4>
            <p>Assets Under Custody</p>
          </div>
          <div className="explore_content">
            <h4 className="text-green">500K+</h4>
            <p>Brokerage Accounts</p>
          </div>
          <div className="explore_content">
            <h4 className="text-green">100+</h4>
            <p>IPOs Settled</p>
          </div>
        </div>
      </section>
      {/* <!-- innovative solution section --> */}
      <section className="innovative_section">
        <div className="container">
          <div className="heading">
            <h2 className="text-center">
              Innovative <span className="text-purple">Solutions</span>
            </h2>
          </div>
          <div className="innovative_inner">
            <div className="empty_grid"></div>
            <div className="grid_text">
              <div className="grid_inner" data-aos="fade-up">
              <a href={`${baseUrl}solutions`}  style={{ color: "#313131" }}>
                <img src="/images/ino-laptop.svg" alt="inulaptop" />{" "}
               <div>
               <h3>
                 
                 API-First Integration
             
             </h3>
             <p data-aos="fade-up">
             supported by a global development and implementation team
           </p>
               </div>
                </a>
              </div>
              
            </div>
            <div className="grid_text">
              <div className="grid_inner" data-aos="fade-up">
              <a href={`${baseUrl}solutions`}  style={{ color: "#313131" }}>
                <img src="/images/ino-notepad.svg" alt="notepad" />{" "}
                <div>
                <h3>Clearing</h3>
                <p data-aos="fade-up">
              build on our scalable infrastructure designed for the future of fintech
              </p>
                </div>
                </a>
              </div>
             
             
            </div>
            <div className="empty_grid"></div>
            <div className="empty_grid"></div>
            <div className="grid_text">
              <div className="grid_inner" data-aos="fade-up">
              <a href={`${baseUrl}solutions`}  style={{ color: "#313131" }}>
                <img src="/images/ino-image.svg" alt="noimg" />
                <div>
                <h3>Custody</h3>
                 <p data-aos="fade-up">
              agnostic account management and custody models within a secure, cloud-based ecosystem
              </p>
                </div>
                </a>
              </div>
             
            </div>
            <div className="grid_text">
              <div className="grid_inner" data-aos="fade-up">
              <a href={`${baseUrl}solutions`}  style={{ color: "#313131" }}>
                <img src="/images/exec.svg" alt="inulaptop" />{" "}
                <div>
                <h3>Execution</h3>
                <p data-aos="fade-up">
                on-demand trade support, low-latency DMA, and smart routing
                optimized for best execution
              </p>
                </div>
                </a>
              </div>
              
            </div>
            <div className="empty_grid"></div>
          </div>
          <div className="button_innovative">
            <button className="btn_pink white-bg">
            <a href={`${baseUrl}solutions`} >View More</a>
            </button>
          </div>
        </div>
      </section>
      {/* <!-- launch faster section --> */}
      <section className="launch_section">
        <div className="heading">
          <h2 className="text-center">
            Launch Faster with{" "}
            <span className="text-purple">Our Modern API</span>
          </h2>
        </div>
        <div className="instant_account">
          <a
            href="https://www.velox-global.com/apidoc/#tag/OpenUpdateClose-accounts/paths/~1open_account/post"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/instant-doc.svg" alt="instantdoc" />{" "}
          </a>
          <h3>Instant Account Opening</h3>
        </div>
        <div className="instant_account_parent">
          <div className="container">
            <div className="instan_account_options ">
              <div className="request_typing">
                <div className="request">
                  <img src="/images/Request.svg" alt="request" />
                </div>
                <div className="response">
                  <img src="/images/response.svg" alt="response" />
                </div>
              </div>
              <div className="list-all">
                <div className="instant_account">
                  <a
                    href="https://www.velox-global.com/apidoc/#tag/Locate-stocks-for-short-selling"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/stock.svg" alt="stock" />
                  </a>
                  <h3>Stock Locates</h3>
                </div>
                <div className="instant_account">
                  <a
                    href="https://www.velox-global.com/apidoc/#tag/Locate-stocks-for-short-selling"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img src="/images/trading.svg" alt="trading" />
                  </a>
                  <h3>Trading</h3>
                </div>
                <div className="instant_account">
                  <a
                    href="https://www.velox-global.com/apidoc/#tag/Transfer-funds"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/images/transfers.svg" alt="transfers" />
                  </a>
                  <h3>Transfers</h3>
                </div>
                <div className="instant_account">
                  <h3>
                    {" "}
                    <a
                      href="https://www.velox-global.com/apidoc/#tag/Transfer-funds"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ...and more
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button_innovative">
          <button className="btn_pink white-bg">
          <a href="https://www.velox-global.com/apidoc/" target="_blank" rel="noreferrer">Start Building</a>
          </button>
        </div>
      </section>
      {/* <!-- broker dealers --> */}
      <section className="broker_section">
        <div className="broker_section_inner">
          <div className="scrolling-words-container">
            <img
              src="/images/logo_single.svg"
              className="heading_img"
              alt="logo"
            />
            <img
              src="/images/menu_logo.svg"
              className="heading_img_small"
              alt="logo"
            />
            <span>empowers</span>
            <div className="wrapper_scrolling">
              <div class="words">
                <span>BROKER DEALERS</span>
                <span>INVESTMENT BANKS</span>
                <span>FINTECHS</span>
                <span>INVESTMENT ADVISORS</span>
                <span>ATSs</span>
                <span>MARKET MAKERS</span>
                <span>HEDGE FUNDS</span>
                <span>ALGO TRADERS</span>
                <span>FAMILY OFFICES</span>
                <span>BROKER DEALERS</span>
              </div>
            </div>
            <span>to grow</span>
          </div>
        </div>
      </section>
      {/* <!-- launch and sclae section --> */}
      <section className="scale_section">
        <div className="heading">
          <h2 className="text-center">
            Launch & <span className="text-purple">Scale</span>
          </h2>
        </div>
        <div className="scale_inner_parent container">
          <div className="scale_inner">
            <div className="transparent_parent">
              <div className="transparent_pricing">
                <div className="header">
                  <div className="instant_account">
                    <img
                      src="/images/transp_doc.svg"
                      className="heading_img"
                      alt="instantdoc"
                    />
                    <h3>Transparent Pricing</h3>
                  </div>
                </div>
                <div className="content">
                  <ul>
                    <li >
                      <img src="/images/lowrate.svg" alt="lowarete" />{" "}
                      <h5  data-aos="fade-up">Low Rates</h5>
                    </li>
                    <li >
                      <img src="/images/contracts.svg" alt="contacts" />{" "}
                      <h5  data-aos="fade-up">No Long Term Contracts</h5>
                    </li>
                    <li >
                      <img src="/images/dollar.svg" alt="dollar" />{" "}
                      <h5  data-aos="fade-up">No Commissions</h5>
                    </li>
                  </ul>
                </div>
              </div>
             
            </div>
            <div className="transparent_pricing personalize">
              <div className="header">
                <div className="instant_account">
                  <img
                    src="/images/personalize.svg"
                    className="heading_img"
                    alt="personalize"
                  />
                  <h3  >Personalized For You</h3>
                </div>
              </div>
              <div className="content">
                <ul>
                  <li >
                    <img src="/images/bulb.svg" alt="bulb" />{" "}
                    <h5  data-aos="fade-up">Unique Solutions</h5>
                  </li>
                  <li >
                    <img src="/images/discount.svg" alt="discount" />{" "}
                    <h5  data-aos="fade-up">Volume Discounts</h5>
                  </li>
                  <li >
                    <img src="/images/Settings.svg" alt="settings" />{" "}
                    <h5 data-aos="fade-up">Customized Services</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="button_innovative ">
                <button className="btn_pink white-bg">
                <a href={`${baseUrl}contact`} >Get Access</a>
                </button>
              </div>
          </div>
          
        </div>
      </section>
      {/* <!-- trusted global partner --> */}
      <section className="global">
        <div className="heading">
          <h2 className="text-center">
            Trusted <span className="text-purple">Global Partners</span>
          </h2>
        </div>
        <div>
          <OwlCarousel items={10} className="owl-caurosel" loop {...options}>
            <div className="item" >
              <img src="/images/c1.svg" alt="c1"/>
            </div>
            <div className="item">
              <img src="/images/c2.svg" alt="c2" />
            </div>
            <div className="item">
              <img src="/images/c3.svg" alt="c3"  />
            </div>
            <div className="item">
              <img src="/images/c4.svg" alt="c4" />
            </div>
            <div className="item">
              <img src="/images/c5.svg" alt="c5"  />
            </div>
            <div className="item">
              <img src="/images/c6.svg" alt="c6"  />
            </div>
            <div className="item">
              <img src="/images/c7.svg" alt="c7"  />
            </div>
            <div className="item">
              <img src="/images/c8.svg" alt="c8"   />
            </div>
            <div className="item">
              <img src="/images/c9.svg" alt="c9"  />
            </div>
            <div className="item">
              <img src="/images/c10.svg" alt="c10"  />
            </div>
            <div className="item">
              <img src="/images/c11.svg" alt="c11"  />
            </div>
            <div className="item">
              <img src="/images/c12.svg" alt="c12"  />
            </div>
            <div className="item">
              <img src="/images/c13.svg" alt="c13"  />
            </div>
            <div className="item">
              <img src="/images/c14.svg" alt="c14"  />
            </div>
            <div className="item">
              <img src="/images/c15.svg" alt="c15"  />
            </div>
            <div className="item">
              <img src="/images/c16.svg" alt="c16"  />
            </div>
          </OwlCarousel>
        </div>
      </section>
      {/* <!-- newsletter section --> */}
      <NewsLetterSection />
    </React.Fragment>
  );
};
export default Home;
