import React from "react"
import { NewsLetterSection } from "../components"
import {Link} from "react-router-dom"

const BlogPage =()=>{
    return(
        <React.Fragment>
        {/* // <!-- blog section --> */}
    <section className="blog mt-92">
      <div className="heading">
        <h1 className="text-center">Blog</h1>
      </div>
      <div className="blog_parent">
        <div className="blog_main_inner">
          <div className="blog_inner">
            <div className="image">
              <img src="/images/blog.jpg" alt="blog"/>
            </div>
            <div className="content">
              <h3>Finra Annual Conference 2022</h3>
              <label>May 2022</label>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Curabitur tempus urna at turpis
                ...
              </p>
              <div className="button_innovative">
                <button className="btn_pink white-bg">Read More</button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- 2nd blog --> */}
        <div className="blog_main_inner">
          <div className="blog_inner">
            <div className="content">
              <h3>Finra Annual Conference 2022</h3>
              <label>May 2022</label>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Curabitur tempus urna at turpis
                ...
              </p>
              <div className="button_innovative">
                <button className="btn_pink white-bg">Read More</button>
              </div>
            </div>
            <div className="image">
              <img src="/images/blog.jpg" alt="blog"/>
            </div>
          </div>
        </div>
      </div>
      <div className="see-more">
        <Link to="/whoweare/blogdetails" className="see_more">See More</Link>
      </div>
    </section>
    <NewsLetterSection />
    </React.Fragment>
    )
}
export default BlogPage ;