import React, {useEffect} from "react"
import {
  Home,
  Solutions,
  DevelopersApi,
  WhoWeAre,
  Contact,
  BlogPage,
  BlogDetailsPage,
  ApiDoc
} from "./pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components";

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-na1.hs-scripts.com/46153892.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
  };},[]);
  return (
    <>
      <BrowserRouter>
       <Layout >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/apidoc" element={<ApiDoc />} />
            <Route path="/whoweare" element={<WhoWeAre />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/clientlogin" element={<ClientLogin />} /> */}
            <Route path="/whoweare/blog" element={<BlogPage/>} />
            <Route path="/whoweare/blogdetails" element={<BlogDetailsPage/>} />
            <Route path="/developerapi" element={<DevelopersApi />} />
          </Routes>
          </Layout>
         
        
      </BrowserRouter>
    </>
  );
}

export default App;
