import React from "react";
import { baseUrl } from "../mainUrl";

const NewsLetterSection = () => {
  return (
    // <!-- newsletter section -->
    <section className="newsletter">
     <div className="container ">
     <div className="newsletter_inner">
     <div className="ready_to_join">
        <h3>
          Ready <br />
          to join?
        </h3>
        <div className="button">
          <button className="btn_pink">
            <a href={`${baseUrl}contact`}>Get Access</a>
          </button>
          <button className="btn-blue outline">
            <a
              href="https://www.velox-global.com/apidoc/"
              target="_blank"
              rel="noreferrer"
            >
              Start Building
            </a>
          </button>
        </div>
      </div>
     </div>
     </div>
    </section>
  );
};
export default NewsLetterSection;
