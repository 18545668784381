import React from "react";
import { Link } from "react-router-dom";

const SubNav = ({ open, setOpenNav}) => {
  // const ref = useOnclickOutside(() => setOpen(false));
  return (
    open && (
      <div  className="sub_nav_child arrow" >
         <ul>
                    <li className="header_sub_item">
                      <Link to="/whoweare"  onClick={() => setOpenNav(false)} >
                        {" "}
                        <div className="sub_nav_img">
                          <img src="/images/about.svg" alt="about" style={{height:"40px", width:"40px"}}/> &nbsp;
                        </div>{" "}
                        About Us {" "}
                      </Link>
                    </li>
                    <li className="header_sub_item" onClick={() => setOpenNav(false)}>

                    <a
                            href="https://www.linkedin.com/company/velox-clearing/jobs/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginTop: "-10px" }}
                          ><div className="sub_nav_img">
                          <img src="/images/Career.svg" alt="Career" />
                        </div>Careers </a>
                      {/* <Link to="#"  onClick={() => setOpenNav(false)}>
                        {" "}
                        <div className="sub_nav_img">
                          <img src="/images/Career.svg" alt="Career" />
                        </div>{" "}
                        Careers{" "}
                      </Link> */}
                    </li>
                    {/* <li className="header_sub_item">
                      <Link to="/whoweare/blog"  onClick={() => setOpenNav(false)}>
                        <div className="sub_nav_img">
                          <img src="/images/blog.svg" alt="blog" />
                        </div>{" "}
                        Blogs
                      </Link>
                    </li> */}
                  </ul>
      </div> 
    )
  );
};
export default SubNav;
