import React from "react";
import { baseUrl } from "../mainUrl";
const Footer = () => {
  return (
    <>
      {/* <!-- footer section --> */}
      <section className="footer">
        <footer className="container">
          <div className="logo">
            <a href={baseUrl}>
              {" "}
              <img src="/images/logo.svg" alt="logo" />
            </a>
          </div>
          <ul>
            <li>About</li>|
            <div className="footer--content">
              {" "}
              <li>
                <a href={`${baseUrl}whoweare`}> Our Team</a>
              </li>
              |
              <li>
                {" "}
                <a
                  href="https://www.linkedin.com/company/velox-clearing/jobs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers{" "}
                </a>
              </li>
            </div>
          </ul>
          <ul>
            <li>Connect</li>|
            <div className="footer--content">
              {" "}
              <li>
                <img src="/images/LinkedInLogo.svg" className="linkedin_logo"  alt="linkedin logo"/>
                <a
                  href="https://www.linkedin.com/company/velox-clearing"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn{" "}
                </a>
              </li>
              |
              <li>
                <img src="/images/Twitterlogo.svg" className="linkedin_logo"  alt="Twitter logo" />
                <a
                  href="https://twitter.com/VeloxClearing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter{" "}
                </a>
              </li>
            </div>
          </ul>
          <ul className="flex-start-mobile">
            <li>Member</li>|
            <div className="footer--content">
              <li>
                <a
                  href="https://www.finra.org/#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  FINRA{" "}
                </a>
              </li>
              |
              <li>
                <a
                  href="https://www.sipc.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  SIPC{" "}
                </a>
              </li>
              |
              <li>
                <a
                  href="https://www.nyse.com/index"
                  target="_blank"
                  rel="noreferrer"
                >
                  NYSC{" "}
                </a>
              </li>
              |
              <li>
                <a
                  href="https://www.dtcc.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DTCC{" "}
                </a>
              </li>
              |
              <li>
                <a
                  href="https://www.dtcc.com/about/businesses-and-subsidiaries/nscc"
                  target="_blank"
                  rel="noreferrer"
                >
                  NSCC
                </a>
              </li>
              |
              <li>
                <a
                  href="https://www.theocc.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  OCC{" "}
                </a>
              </li>
            </div>
          </ul>
          <ul className="flex-start-mobile">
            <li>
              <a href={`${baseUrl}contact`}>Contact </a>
            </li>
            |
            <li>
              <span className="contact_footer">
                <a href="tel:866.808.3569">
                  866.80.VELOX <span>(866.808.3569)</span>
                </a>
              </span>{" "}
              |
              <span className="contact_footer">
                &nbsp;
                <a
                  href="mailto:sales@velox-global.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  sales@velox-global.com
                </a>
              </span>{" "}
              | &nbsp;
              <span className="contact_footer">
                600 Brickell Ave, Suite 2800, Miami, FL 33131
              </span>
            </li>
          </ul>
          <div className="bottom_line"></div>
          <p>
            ©2022 Velox Clearing LLC, a Limited Liability Corporation. All
            Rights Reserved.{" "}
            <span className="footer_external_link">
              <a
                href="https://brokercheck.finra.org/"
                target="_blank"
                rel="noreferrer"
                className="stock_img"
              >
                BrokerCheck
              </a>
              .
              <a
                href="https://resources.velox-global.com/index.php/terms-of-use/"
                target="_blank"
                rel="noreferrer"
                className="stock_img"
              >
                Terms of Use
              </a>
              .
              <a
                href="https://resources.velox-global.com/index.php/disclosures/"
                target="_blank"
                rel="noreferrer"
                className="stock_img"
              >
                Disclosures
              </a>
              .
              <a
                href="https://resources.velox-global.com/index.php/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="stock_img"
              >
                Privacy Notice
              </a>
              .
              <a
                href="https://resources.velox-global.com/index.php/documents/"
                target="_blank"
                rel="noreferrer"
                className="stock_img"
              >
                Document Links
              </a>
            </span>
          </p>
        </footer>
      </section>
    </>
  );
};
export default Footer;
