import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet-async";
import { baseUrl } from "../mainUrl";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleNameChange = (e) => {
    setError("");
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setError("");
    setEmail(e.target.value);
  };
  const handleMessageChange = (e) => {
    setError("");
    setMessage(e.target.value);
  };

  function sendEmail(e) {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setError("kindly fill all the fields");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!email.match(emailRegex)) {
      setError("kindly enter valid email id");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_.SERVICE_ID,
        process.env.REACT_APP_.TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_.PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          // alert("sent message succesfully");
          document.getElementById("contact-form").reset();
          setName("");
          setError("");
          setEmail("");
          setMessage("");
          setShow(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>veloxglobal.com/contact</title>
        <meta
          name="description"
          content="velox linkedin| velox contact form|market-leading broker clearing service|RIA custodian"
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      {/* <!-- let's connect section --> */}
      <section className="let_connect mt-92 bg_dark">
        <div className="heading">
          <h1 className="text-center">
            Let’s <span className="text-purple">Connect</span> !
          </h1>
          {error !== "" && (
            <div
              style={{
                color: "red",
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              {error}
            </div>
          )}
        </div>
        <div
          className="contact_box_parent"
        
        >
          <div className="contact_box_inner">
           <div className="box_parent">
           {!show ? (
              <form className="form_bg" id="contact-form" onSubmit={sendEmail}>
                <div className="form-group">
                  <label htmlFor="Name">Name</label>
                  <input
                    type="text"
                    name="from_name"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Name">Email</label>
                  <input
                    type="text"
                    name="reply_to"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                  />
                </div>
                <div className="button_innovative">
                  <button className="btn_pink">Get In Touch</button>
                </div>
              </form>
            ) : (
              <div className="form_bg" style={{ textAlign: "center" }}>
               <div className="after-login">
               <div className="heading">
                  <h1 className="text-center">
                     <span className="text-green">Thank you!</span>
                  </h1>
                 
                </div>
                <h6>Our Sales Team will get in touch with you soon.</h6>
                  <div className="button_innovative">
                  <button className="btn_pink w-100"><a href={baseUrl} >Back to Home Page</a></button>
                </div>
               </div>
              </div>
            )}
           </div>
          </div>
        </div>
      </section>
      <section className="contact_us_parent">
        <div className="contact_us">
          <div className="contact_us_inner">
            <div className="d-flex">
              <img src="/images/phone.svg" alt="phone" />
              <h4>Phone</h4>
            </div>
            <div className="contact_detail">
              <p>
                <a href="tel:866.80.VELOX (866.808.3569)">
                  866.80.VELOX (866.808.3569)
                </a>
              </p>
            </div>
          </div>
          <div className="contact_us_inner">
            <div className="d-flex">
              <img src="/images/mail.svg" alt="mail" />
              <h4>Email</h4>
            </div>
            <div className="contact_detail">
              <p>
                <a href="mailto:sales@velox-global.com">
                  sales@velox-global.com
                </a>
              </p>
            </div>
          </div>
          <div className="contact_us_inner">
            <div className="d-flex">
              <img src="/images/location.svg" alt="location" />
              <h4>Address</h4>
            </div>
            <div className="contact_detail">
              <p>600 Brickell Ave, Suite 2800, Miami, FL 33131</p>
            </div>
          </div>
          <div className="contact_us_inner">
            <div className="d-flex">
              <img src="/images/social.svg" alt="socail" />
              <h4>Social</h4>
            </div>
            <div className="contact_detail">
              <div className="d-flex">
                <a
                  href="https://www.linkedin.com/company/velox-clearing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/likedin_big.svg" alt="linkedin" />
                </a>
                <a
                  href="https://twitter.com/VeloxClearing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/images/twitter-big.svg" alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Contact;
