import React from "react"
import { RedocStandalone } from 'redoc';

const ApiDoc =()=>{
    return(
        <React.Fragment>
        <div style={{
            "padding-top": "92px",
        }}>
            <RedocStandalone
                specUrl="./velox_api_spec.yaml"
                options={{
                }}
                onLoaded={error => {
                    if (!error) {
                      document.getElementsByClassName("menu-content")[0].style.top = "92px"; // Match this with the padding on line 8 so it doesn't move upwards when scrolling
                    }
                }}
            />
        </div>
        </React.Fragment>
    )
}
export default ApiDoc;