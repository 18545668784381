import React from "react"

const ClientLogin =()=>{
    return(
        <React.Fragment>
        <br/>
            <br/>
            <br/>
            <br/>
        This is clientLogin
        </React.Fragment>
    )
}
export default ClientLogin;