import React, { useEffect } from "react";
import $ from "jquery";

const DevelopersApi = () => {
  useEffect(() => {
    $("#mtawkchat-minified-iframe-element")
      .contents()
      .find("head")
      .append(
        $(
          "<style type='text/css'>" +
            ".YzuTm  {" +
            "display: none !important;" +
            "} " +
            "</style>"
        )
      );
  }, []);
  return (
    <React.Fragment>
      <section className="developerapi mt-92" >
        <div className="developer_inner" style={{marginLeft:"100px"}}>
          <iframe
            id="mtawkchat-minified-iframe-element"
            frameborder="0"
            src="https://www.velox-global.com/apidoc/"
            height="470px"
            width="95%"
            title="developer api"
            //allowfullscreen
            //scrolling="no"
            style={{textAlign:"right", marginleft:"0px"}}

          ></iframe>
        </div>
        <br/>
      </section>
    </React.Fragment>
  );
};
export default DevelopersApi;
