import "../src/assets/css/main.scss"
import React from 'react';
import ReactDOM from 'react-dom/client';
// import "../src/assets/css/main.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider} from "react-helmet-async"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
 
    <App />
    </HelmetProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
