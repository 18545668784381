import React from 'react';
import {Header, Footer } from "./index"
import { useLocation } from 'react-router-dom';
const Layout = ({ children }) => {
    const location = useLocation()
    // console.log("location", typeof location.pathname)
  
    return (
    <React.Fragment>
        <Header />
            <React.Fragment>{children}</React.Fragment>
       {location.pathname !== "/developerapi" &&  <Footer/> }
    </React.Fragment>
    );
};
export default Layout;