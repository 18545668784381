import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SubNav from "./SubNav";
//import ApiSubNav from "./ApiSubNav";
import { useMediaQuery } from "react-responsive";
import { baseUrl } from "../mainUrl";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  // const [openApiNav, setOpenApiNav] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const location = useLocation();

  return (
    <>
      {!isTabletOrMobile ? (
        <section className="header_main ">
          <header className="container d-flex justify-content-between">
            <div className="logo">
              <a href={baseUrl}>
                <img src="/images/logo.svg " alt="logo" />
              </a>
            </div>
            <nav>
              <ul className="d-flex">
                <li
                  className={
                    location.pathname === "/solutions"
                      ? "header_item active"
                      : "header_item"
                  }
                >
                  <a href={`${baseUrl}solutions`}>Solutions</a>
                </li>
                <li className="header_item">
                  <a href={`${baseUrl}apidoc`}>
                    Developer API{" "}
                  </a>
                  {/* <Link to="/developerapi">Developer API </Link> */}
                  {/* subnav header */}
                  {/* <div className="sub_nav_child">
                    <ul>
                      <li className="header_sub_item">
                        <Link to="/whoweare">
                          {" "}
                          <div className="sub_nav_img">
                            <img src="/images/account_open.svg" alt="Account Open" />
                          </div>{" "}
                          Account Openning{" "}
                        </Link>
                      </li>
                      <li className="header_sub_item">
                        <Link to="#">
                          {" "}
                          <div className="sub_nav_img">
                            <img src="/images/trading_open.svg" alt="Trading " />
                          </div>{" "}
                          Trading{" "}
                        </Link>
                      </li>
                      <li className="header_sub_item">
                        <Link to="/whoweare/blog">
                          <div className="sub_nav_img">
                            <img src="/images/Transfer_open.svg" alt="Transfer" />
                          </div>{" "}
                          Transfers
                        </Link>
                      </li>
                    </ul>
                   </div> */}
                </li>
                <li className="header_item sub_nav">
                  {" "}
                  <a href={`${baseUrl}whoweare`}>Who We Are</a>
                  {/* subnav header */}
                  <div className="sub_nav_child ">
                  <ul>
                        <li className="header_sub_item">
                          <a href={`${baseUrl}whoweare`}>
                            {" "}
                            <div className="sub_nav_img">
                              <img src="/images/about.svg" alt="about" />
                            </div>{" "}
                            About Us{" "}
                          </a>
                        </li>
                        <li className="header_sub_item">
                          <a
                            href="https://www.linkedin.com/company/velox-clearing/jobs/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginTop: "-10px" }}
                          >
                            {" "}
                            <div className="sub_nav_img">
                              <img
                                src="/images/Career.svg"
                                alt="Career"
                                style={{
                                  height: "110px",
                                  width: "180px",
                                  marginTop: "10px",
                                }}
                              />
                            </div>{" "}
                            Careers{" "}
                          </a>
                        </li>
                        {/* <li className="header_sub_item">
                          <a href={`${baseUrl}whoweare/blog`}>
                            <div className="sub_nav_img">
                              <img src="/images/blog.svg" alt="blog" />
                            </div>{" "}
                            Blogs
                          </a>
                        </li> */}
                      </ul>
                  </div>
                </li>
                <li
                  className={
                    location.pathname === "/contact"
                      ? "header_item active"
                      : "header_item"
                  }
                >
                  <a href={`${baseUrl}contact`}>Contact Us </a>
                </li>
                <li className="header_item">
                  <a
                    href="https://www.velox-clearing.com/portal/login?language=en_US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Client Log In{" "}
                  </a>
                </li>
              </ul>
            </nav>
          </header>
        </section>
      ) : (
        <section className="mobile-nav">
          <div className="mobile_nav_inner">
            <a href={baseUrl}>
            <img src="/images/logo.svg" className="logo_mobile" alt="logo" /></a>
            <div
              onClick={() => setOpenNav(true)}
              style={{ display: openNav ? "none" : "block" }}
            >
              <img src="/images/menu_line.svg" alt="svg" />
            </div>
          </div>
          <div
            className="open_menu_res"
            style={{ display: openNav ? "block" : "none" }}
          >
            <div className="close_btn">
              <img
                src="/images/crossMenu.svg"
                alt="svg"
                onClick={() => setOpenNav(false)}
              />
            </div>

            <nav>
              <ul>
                <li>
                  <Link to="/solutions"  onClick={() => setOpenNav(false)}>Solutions</Link>
                </li>
                <li
                  onClick={() => setOpenNav(false)}
                  className="sub_nav"
                >
                  {/* <Link to="/developerapi">Developer API </Link> */}
                  <a
                    href="https://www.velox-global.com/apidoc/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Developer API{" "}
                  </a>
                  {/* Developer API  <span className="down_drop"><img src="/images/down_drop.svg" alt="drop" /></span> */}
                  {/* <ApiSubNav openApiNav={openApiNav} setOpenApiNav={setOpenApiNav} /> */}
                </li>
                <li onClick={() => setOpen(!open)} className="sub_nav">
                  Who We Are{" "}
                  <span className="down_drop">
                    <img src="/images/down_drop.svg" alt="drop" />
                  </span>
                  <SubNav open={open} setOpen={setOpen} setOpenNav={setOpenNav}/>
                </li>
                <li>
                  <Link to="/contact"  onClick={() => setOpenNav(false)}>Contact Us </Link>
                </li>
                <li  onClick={() => setOpenNav(false)}>
                  <a
                    href="https://www.velox-clearing.com/portal/login?language=en_US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Client Log In{" "}
                  </a>
                </li>
              </ul>
            </nav>
            <div className="mobile_nav_bottom">
              <div className="logo">
                <Link to="/">
                  <img src="/images/menu_logo.svg " alt="logo" />
                </Link>
              </div>
              <p>
                <a href="mailto:sales@velox-global.com">
                  sales@velox-global.com
                </a>
              </p>
              <p>
                {" "}
                <a href="tel:866.808.3569">866.80.VELOX (866.808.3569)</a>
              </p>
              <p>600 Brickell Ave, Suite 2800, Miami, FL 33131</p>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Header;
