/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NewsLetterSection } from "../components";
import { ReactComponent as Curve } from "../assets/img/curve_timeline.svg";
import { ReactComponent as Curveteam } from "../assets/img/curve_team.svg"; 
import Curveteam2 from  "../assets/img/curve-1.svg"; 
import Curveteam3 from  "../assets/img/curve2.svg"; 
import { Helmet } from "react-helmet-async";

const WhoWeAre = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>velox team</title>
          <meta name="description" content="velox team|velox ceo| velox founder|velox history" />
        <link rel="canonical" href="/whoweare" />
      </Helmet>
      {/* <!-- mission section --> */}
      <section className="scale_section mt-92 bg_dark new_scale">
        <div className="scale_inner_parent container">
          <div className="scale_inner">
           <img src={Curveteam2} className="scale_inner_svg" alt="team2"/>
            <div className="transparent_parent">
              <div className="transparent_pricing">
                <div className="header">
                  <div className="instant_account">
                    <img src="/images/mission.svg" alt="mission" />
                    <h2>We are...</h2>
                  </div>
                </div>
                <div className="content">
                  <ul>
                    <li>
                    <img src="/images/lock.svg" alt="lock" />
                      <h5>Demonopolizing the clearing industry</h5>
                    </li>
                    <li>
                      <img src="/images/cloud.svg" alt="saclebi" />{" "}
                      <h5>
                        Providing low-cost clearing and custody infrastructure
                      </h5>
                    </li>
                    <li>
                      <img src="/images/api.svg" alt="low" />{" "}
                      <h5>Platform agnostic</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <img src={Curveteam3} className="scale_inner_svg2" alt="team3"/>
            <div className="transparent_pricing personalize">
            
              <div className="header">
                <div className="instant_account">
                  <img src="/images/vision.svg" alt="vision" />
                  <h2>You are...</h2>
                </div>
              </div>
              <div className="content">
                <ul>
                  <li>
                    <img src="/images/graph.svg" alt="lock" />{" "}
                    <h5>Looking for a scalable and flexible solution</h5>
                  </li>
                  <li>
                  <img src="/images/code.svg" alt="code" />{" "}
                 
                    <h5>Technology-forward</h5>
                  </li>
                  <li>
                    <img src="/images/scalebi.svg" alt="saclebi" />
                    <h5>Fed up with legacy software</h5>
                  </li>
                </ul>
              </div>
           
            </div>
            
          </div>
        </div>
      </section>
      {/* <!-- timeline section --> */}
      <section className="timeline bg_dark">
        <div className="timeline_inner">
          <Curve />
        </div>
      </section>
      {/* <!-- meetour leader section --> */}
      <section className="meetleader">
        <div className="meet_parent_leader">
          <div className="heading">
            <h2 className="text-center">
              Meet Our <span className="text-purple">Leaders</span>
            </h2>
          </div>
          <div className="content">
            <a
              href="https://www.linkedin.com/in/bingshan-song-43960b118/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team1.svg" alt="team1" />
                  <a className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="linkn" />
                  </a>
                </div>

                <h6>Bingo Song</h6>
                <p>Chief Executive Officer</p>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/gaelin-monkman-kotz-348836b4/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team2.svg" alt="team1" />
                  <a href="" className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="linkin" />
                  </a>
                </div>
                <h6>Gaelin Monkman-Kotz</h6>
                <p>Co-Chief Executive Officer</p>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/robert-milburn-cpa/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team3.svg" alt="team3" />
                  <a className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="bgwhite" />
                  </a>
                </div>
                <h6>Robert Milburn</h6>
                <p>Chief Operating Officer</p>
              </div>
            </a>
          {/*  <a
              href="https://www.linkedin.com/in/anup-varkey/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team4.svg" alt="team4" />
                  <a className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="linkn" />
                  </a>
                </div>
                <h6>Anup Varkey</h6>
                <p> Chief Growth Officer</p>
              </div>
            </a> */}
            <a
              href="https://www.linkedin.com/in/hermean-wong-82289622a/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team5.svg" alt="team5" />
                  <a className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="bg white" />
                  </a>
                </div>
                <h6>Hermean Wong</h6>
                <p> Chief Technology Officer</p>
              </div>
            </a>
            <a
              href="https://www.linkedin.com/in/amirmontasser/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="team">
                <div className="image">
                  <img src="/images/team6.svg" alt="team6" />
                  <a className="linkedin_logo">
                    <img src="/images/LinkedIn.svg" alt="linkn" />
                  </a>
                </div>
                <h6>Amir Montasser</h6>
                <p>Chief Financial Officer</p>
              </div>
            </a>
          </div>
          <div className="content_more">
            <h2>Join Our Team!</h2>
            <div className="button_innovative ">
              <button className="btn_pink white-bg" style={{ padding: "10px" }}>
                {" "}
                <a
                  href="https://www.linkedin.com/company/velox-clearing/jobs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  See Open Positions
                </a>{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="curve_timeline">
        <Curveteam />
      </section>
      <NewsLetterSection />
    </React.Fragment>
  );
};
export default WhoWeAre;
